html {
  overflow: hidden;
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  height: 100%;
  overflow: auto;
  font-size: 0.875rem
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

[type=text], [type=tel], [type=email], [type=password], [type=number], [type=date], [type=search], textarea {
  padding: .5rem;
  font-size: .875rem;
  line-height: 1;
  color: #4E5A5E;
}
